
/* ////////// Variables ////////// */

:root {
  --surface-a: #ffffff;
  --surface-b: #efefef;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #212529;
  --text-color-secondary: #6c757d;
  --primary-color: #6293de;
  --primary-color-text: #ffffff;
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Aria, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --surface-ground: #ffffff;
  --logo-color-1: #1a3654;
  --logo-color-2: #($logoColor2);
  --logo-bg-title-bar: #(shade000);
}

/* ////////// General ////////// */

* {
  box-sizing: border-box;
}

.p-component {
  font-family:
    "Open Sans",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji";
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  transition-duration: 0.2s;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.65;
}

.p-error,
.p-invalid {
  display: flex;
  gap: .5rem;
  margin-top: .375rem;
  font-size: .75rem;
  color: var(--danger);
}

.p-text-secondary {
  color: 6c757d;
}

.pi {
  font-size: 1rem;
}

.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px var(--main-25);
}

a,
a:visited {
  color: var(--main);
}

a:hover {
  color: var(--main-light);
}

::-webkit-input-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

/* ////////// Button ////////// */

/*

<button pButton type="button" class="CLASSES">
    <fa-icon [icon]="STARTICON"></fa-icon>
    Label
    <fa-icon [icon]="ENDICON"></fa-icon>
</button>

CLASSES:

Color: default, main, success, warning, danger
Type: outline, link
Size: small
Full width: full, fullMobile
Shape: square

*/

/* ///// Base ///// */

.p-button {
  gap: 1rem;
  min-width: 2.625rem;
  padding: 0.625rem 1rem 0.625rem 1rem;
  border: 1px solid;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  transition: color 200ms, background-color 200ms, border-color 200ms, box-shadow 200ms, opacity 200ms;

  fa-icon {
    font-size: .875rem;
  }
}

.p-button.full {
  width: 100%;
  justify-content: center;
}

@media (max-width: 767px) {
  .p-button.fullMobile {
    width: 100%;
    justify-content: center;
  }
}

.p-button.square {
  align-items: center;
  justify-content: center;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0;

  fa-icon {
    font-size: 1rem;
  }
}

/* ///// Filled ///// */

.p-button, .p-button.main, .p-button:visited {
  background: var(--main);
  border-color: var(--main);
  color: #FFFFFF;
}
.p-button:not(:disabled):hover, .p-button.main:not(:disabled):hover {
  background: var(--main-light);
  border-color: var(--main-light);
  color: #FFFFFF;
}
.p-button:not(:disabled):active, .p-button.main:not(:disabled):active {
  background: var(--main-dark);
  border-color: var(--main-dark);
}
.p-button:focus, .p-button.main:focus {
  background: var(--main);
  border-color: var(--main);
  box-shadow: 0px 0px 0px 2px var(--main-25);
}
.p-button:disabled, .p-button.main:disabled {
  background: var(--main-60);
  border-color: transparent;
}

.p-button.default, .p-button.default:visited {
  background: var(--dark);
  border-color: var(--dark);
  color: #FFFFFF;
}
.p-button.default:not(:disabled):hover {
  background: var(--light);
  border-color: var(--light);
  color: #FFFFFF;
}
.p-button.default:not(:disabled):active {
  background: #000000;
  border-color: #000000;
}
.p-button.default:focus {
  background: var(--dark);
  border-color: var(--dark);
  box-shadow: 0px 0px 0px 2px var(--mono-25);
}
.p-button.default:disabled {
  background: var(--mono-60);
  border-color: transparent;
}

.p-button.success, .p-button.success:visited {
  background: var(--success);
  border-color: var(--success);
  color: #FFFFFF;
}
.p-button.success:not(:disabled):hover {
  background: var(--success-light);
  border-color: var(--success-light);
  color: #FFFFFF;
}
.p-button.success:not(:disabled):active {
  background: var(--success-dark);
  border-color: var(--success-dark);
}
.p-button.success:focus {
  background: var(--success);
  border-color: var(--success);
  box-shadow: 0px 0px 0px 2px var(--success-25);
}
.p-button.success:disabled {
  background: var(--success-60);
  border-color: transparent;
}

.p-button.danger, .p-button.danger:visited {
  background: var(--danger);
  border-color: var(--danger);
  color: #FFFFFF;
}
.p-button.danger:not(:disabled):hover {
  background: var(--danger-light);
  border-color: var(--danger-light);
  color: #FFFFFF;
}
.p-button.danger:not(:disabled):active {
  background: var(--danger-dark);
  border-color: var(--danger-dark);
}
.p-button.danger:focus {
  background: var(--danger);
  border-color: var(--danger);
  box-shadow: 0px 0px 0px 2px var(--danger-25);
}
.p-button.danger:disabled {
  background: var(--danger-60);
  border-color: transparent;
}

.p-button.warning, .p-button.warning:visited {
  background: var(--warning);
  border-color: var(--warning);
  color: var(--dark);
}
.p-button.warning:not(:disabled):hover {
  background: var(--warning-light);
  border-color: var(--warning-light);
  color: var(--dark);
}
.p-button.warning:not(:disabled):active {
  background: var(--warning-dark);
  border-color: var(--warning-dark);
  color: #FFFFFF;
}
.p-button.warning:focus {
  background: var(--warning);
  border-color: var(--warning);
  color: var(--dark);
  box-shadow: 0px 0px 0px 2px var(--warning-25);
}
.p-button.warning:disabled {
  background: var(--warning-60);
  border-color: transparent;
  color: var(--mono-60);
}

/* ///// Outline ///// */

.p-button.outline {
  background-color: transparent;
}

.p-button.outline, .p-button.outline.main, .p-button.outline:visited {
  border-color: var(--main);
  color: var(--main);
}
.p-button.outline:not(:disabled):hover, .p-button.outline.main:not(:disabled):hover {
  border-color: var(--main-light);
  color: var(--main-light);
  background-color: transparent;
}
.p-button.outline:not(:disabled):active, .p-button.outline.main:not(:disabled):active {
  background: transparent;
  border-color: var(--main-dark);
  color: var(--main-dark);
}
.p-button.outline:focus, .p-button.outline.main:focus {
  background: transparent;
  border-color: var(--main);
  color: var(--main);
  box-shadow: 0px 0px 0px 2px var(--main-25);
}
.p-button.outline:disabled, .p-button.outline.main:disabled {
  background: var(--main-10);
  color: var(--main-60);
  border-color: var(--main-60);
}

.p-button.outline.default, .p-button.outline.default:visited {
  background: transparent;
  border-color: var(--dark);
  color: var(--dark);
}
.p-button.outline.default:not(:disabled):hover {
  background: transparent;
  border-color: var(--light);
  color: var(--light);
}
.p-button.outline.default:not(:disabled):active {
  background: transparent;
  border-color: #000000;
  color: #000000;
}
.p-button.outline.default:focus {
  background: transparent;
  border-color: var(--dark);
  color: var(--dark);
  box-shadow: 0px 0px 0px 2px var(--mono-25);
}
.p-button.outline.default:disabled {
  background: var(--mono-10);
  border-color: var(--mono-60);
  color: var(--mono-60);
}

.p-button.outline.success, .p-button.outline.success:visited {
  background: transparent;
  border-color: var(--success);
  color: var(--success);
}
.p-button.outline.success:not(:disabled):hover {
  background: transparent;
  border-color: var(--success-light);
  color: var(--success-light);
}
.p-button.outline.success:not(:disabled):active {
  background: transparent;
  border-color: var(--success-dark);
  color: var(--success-dark);
}
.p-button.outline.success:focus {
  background: transparent;
  border-color: var(--success);
  box-shadow: 0px 0px 0px 2px var(--success-25);
  color: var(--success);
}
.p-button.outline.success:disabled {
  background: var(--success-10);
  border-color: var(--success-60);
  color: var(--success-60);
}

.p-button.outline.danger, .p-button.outline.danger:visited {
  background: transparent;
  border-color: var(--danger);
  color: var(--danger);
}
.p-button.outline.danger:not(:disabled):hover {
  background: transparent;
  border-color: var(--danger-light);
  color: var(--danger-light);
}
.p-button.outline.danger:not(:disabled):active {
  background: transparent;
  border-color: var(--danger-dark);
  color: var(--danger-dark);
}
.p-button.outline.danger:focus {
  background: transparent;
  border-color: var(--danger);
  box-shadow: 0px 0px 0px 2px var(--danger-25);
  color: var(--danger);
}
.p-button.outline.danger:disabled {
  background: var(--danger-10);
  border-color: var(--danger-60);
  color: var(--danger-60);
}

.p-button.outline.warning, .p-button.outline.warning:visited {
  background: transparent;
  border-color: var(--warning);
  color: var(--dark);
}
.p-button.outline.warning:not(:disabled):hover {
  background: transparent;
  border-color: var(--warning-light);
  color: var(--dark);
}
.p-button.outline.warning:not(:disabled):active {
  background: transparent;
  border-color: var(--warning-dark);
  color: var(--dark);
}
.p-button.outline.warning:focus {
  background: transparent;
  border-color: var(--warning);
  box-shadow: 0px 0px 0px 2px var(--warning-25);
  color: var(--dark);
}
.p-button.outline.warning:disabled {
  background: var(--warning-10);
  border-color: var(--warning-60);
  color: var(--mono-60);
}

/* ///// Small ///// */

.p-button.small {
  gap: .75rem;
  min-width: 2.25rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.4375rem 0.75rem;

  fa-icon {
    font-size: .75rem;
  }

  &.square {
    width: 2.25rem;
    height: 2.25rem;

    fa-icon {
      font-size: .875rem;
    }
  }
}

/* ///// Link ///// */

.p-button.link {
  gap: .75rem;
  min-width: unset;
  font-size: .875rem;
  border: transparent;
  background-color: transparent;
  padding: 0rem;
  font-weight: bold;

  fa-icon {
    font-size: .75rem;
  }

  &.square fa-icon {
    font-size: 1rem;
  }
}

.p-button.link.small {
  min-width: unset;
  border: transparent;
  background-color: transparent;
  padding: 0;
  font-family: Open Sans;
  font-size: .75rem;
  font-weight: bold;

  fa-icon {
    font-size: .625rem;
  }

  &.square fa-icon {
    font-size: .875rem;
  }
}

.p-button.link, .p-button.link.main, .p-button.link:visited {
  background: transparent;
  border: transparent;
  color: var(--main);
}
.p-button.link:not(:disabled):hover, .p-button.link.main:not(:disabled):hover {
  background: transparent;
  border-color: transparent;
  color: var(--main-light);
}
.p-button.link:not(:disabled):active, .p-button.link.main:not(:disabled):active {
  background: transparent;
  border-color: transparent;
  color: var(--main-dark);
}
.p-button.link:focus, .p-button.link.main:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: 0px 0px 0px 2px var(--main-25);
  color: var(--main);
}
.p-button.link:disabled, .p-button.link.main:disabled {
  background: transparent;
  border-color: transparent;
  color: var(--main-60);
}

.p-button.link.default, .p-button.link.default:visited {
  background: transparent;
  border-color: transparent;
  color: var(--dark);
}
.p-button.link.default:not(:disabled):hover {
  background: transparent;
  border-color: transparent;
  color: var(--light);
}
.p-button.link.default:not(:disabled):active {
  background: transparent;
  border-color: transparent;
  color: #000000;
}
.p-button.link.default:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: 0px 0px 0px 2px var(--mono-25);
  color: var(--dark);
}
.p-button.link.default:disabled {
  background: transparent;
  border-color: transparent;
  color: var(--mono-60);
}

.p-button.link.success, .p-button.link.success:visited {
  background: transparent;
  color: var(--success);
}
.p-button.link.success:not(:disabled):hover {
  background: transparent;
  border-color: transparent;
  color: var(--success-light);
}
.p-button.link.success:not(:disabled):active {
  background: transparent;
  border-color: transparent;
  color: var(--success-dark);
}
.p-button.link.success:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: 0px 0px 0px 2px var(--success-25);
  color: var(--success);
}
.p-button.link.success:disabled {
  background: transparent;
  border-color: transparent;
  color: var(--success-60);
}

.p-button.link.danger, .p-button.link.danger:visited {
  background: transparent;
  border-color: transparent;
  color: var(--danger);
}
.p-button.link.danger:not(:disabled):hover {
  background: transparent;
  border-color: transparent;
  color: var(--danger-light);
}
.p-button.link.danger:not(:disabled):active {
  background: transparent;
  border-color: transparent;
  color: var(--danger-dark);
}
.p-button.link.danger:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: 0px 0px 0px 2px var(--danger-25);
  color: var(--danger);
}
.p-button.link.danger:disabled {
  background: transparent;
  border-color: transparent;
  color: var(--danger-60);
}

.p-button.link.warning, .p-button.link.warning:visited {
  background: transparent;
  border-color: transparent;
  color: var(--warning-dark);
}
.p-button.link.warning:not(:disabled):hover {
  background: transparent;
  border-color: transparent;
  color: var(--warning);
}
.p-button.link.warning:not(:disabled):active {
  background: transparent;
  border-color: transparent;
  color: var(--dark);
}
.p-button.link.warning:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: 0px 0px 0px 2px var(--warning-25);
  color: var(--warning-dark);
}
.p-button.link.warning:disabled {
  background: transparent;
  border-color: transparent;
  color: var(--mono-60);
}

/* ////////// Input ////////// */

.p-inputtext,
.p-dropdown,
.p-treeselect {
  padding: 0.5625rem 1rem;
  font-family: Open Sans;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.375rem;
  background: #ffffff;
  border: 1px solid var(--outline);
  border-radius: 0;
  color: var(--dark);
  transition: background-color 200ms, border-color 200ms, box-shadow 200ms;
}

.p-inputtext.small,
.p-dropdown.small,
.p-inputtext.small {
  padding: 0.5625rem 1rem;
  font-size: .875rem;
  line-height: 1.1875rem;
}

.p-inputtext:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled):hover,
.p-treeselect:not(.p-disabled):hover {
  border-color: var(--outline);
}

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus,
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px var(--main-25);
  border-color: var(--main);
}

.p-inputtext.ng-dirty.ng-invalid,
.p-inputtext.ng-touched.ng-invalid,
.p-dropdown.ng-dirty.ng-invalid,
.p-dropdown.ng-touched.ng-invalid,
.p-treeselect.ng-dirty.ng-invalid,
.p-treeselect.ng-touched.ng-invalid {
  border-color: var(--danger);
}

.p-inputtext.ng-dirty.ng-invalid:hover,
.p-dropdown.ng-dirty.ng-invalid:hover,
.p-treeselect.ng-dirty.ng-invalid:hover {
  border-color: var(--danger);
}

.p-inputtext.ng-dirty.ng-invalid:focus,
.p-dropdown.ng-dirty.ng-invalid:focus,
.p-treeselect.ng-dirty.ng-invalid:focus {
  border-color: var(--danger);
  box-shadow: 0 0 0 2px var(--danger-25);
}

.p-inputtext:disabled,
.p-dropdown.p-disabled,
.p-multiselect.p-disabled,
.p-treeselect.p-disabled,
textarea[readonly],
p-editor-content.ql-disabled .ql-editor {
  border: 1px solid var(--outline);
  background-color: var(--bkg);
  opacity: 1;
}

.p-dropdown.p-disabled .p-dropdown-label,
.p-treeselect.p-disabled .p-treeselect-label {
  color: var(--light);
}

input::placeholder,
textarea::placeholder {
  font-family: Open Sans;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.375rem;
  text-align: left;
  font-style: normal;
}

input:disabled {
  background: var(--bkg);
  border: 1px solid var(--outline);
}

textarea {
  resize: vertical;
}

p-treeselect.w-full .p-treeselect,
p-dropdown.w-full .p-dropdown,
p-multiselect.w-full .p-multiselect {
  width: 100%;
}

/* ////////// Dropdown ////////// */

.p-dropdown .p-dropdown-trigger,
.p-treeselect .p-treeselect-trigger {
  width: unset;
  margin-left: 1rem;
  color: var(--light);
}
.p-dropdown-trigger-icon,
.p-treeselect-trigger-icon {
  font-size: .875rem;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}

.p-dropdown .p-dropdown-label,
.p-treeselect .p-treeselect-label {
  padding: 0;
  line-height: 1.375rem;
  background: transparent;
  border: 0 none;
}

.p-dropdown .p-dropdown-label.p-placeholder,
.p-treeselect .p-treeselect-label.p-placeholder {
  color: var(--light);
}

.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-dropdown p.dropdown-trigger {
  background: transparent;
  color: var(--light);
  width: 2.357rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-dropdown .p-dropdown-clear-icon {
  color: var(--light);
  right: 2.357rem;
}

.p-dropdown-panel,
.p-dropdown-header,
.p-treeselect-panel {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  color: var(--dark);
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}

p.dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: var(--dark);
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-tree {
  min-width: 10rem;
  margin: 0;
  padding: 0.875rem 1rem;
  border: none;
  border-radius: 0;
  font-size: .875rem;
  line-height: normal;
  color: var(--dark);
  background: transparent;
  transition: color 200ms, background-color 200ms, box-shadow 200ms, opacity 200ms;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: var(--main);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--dark);
  background: var(--mono-10);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  min-width: 10rem;
  padding: 0.875rem 1rem;
  font-size: .875rem;
  color: var(--dark);
  background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: var(--dark);
  background: #ffffff;
  font-weight: 600;
}

.p-dropdown-panel.p-error,
.p-dropdown-panel.p-invalid {
  border-color: var(--danger);
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: var(--danger);
}

.p-input-filled .p-dropdown {
  background: white;
}

.p-input-filled p.dropdown:not(.p-disabled):hover {
  background-color: white;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: white;
}

/* ////////// Radio / Checkbox ////////// */

.field-checkbox > label, .field-radiobutton > label {
  margin-left: .75rem;
  font-size: .875rem;
}

.p-radiobutton,
.p-checkbox {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.p-radiobutton .p-radiobutton-box,
.p-checkbox .p-checkbox-box {
  flex-shrink: 0;
  border: 2px solid var(--outline);
  background: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1000px;
  transition: color 200ms, border 200ms, background-color 200ms, opacity 200ms, box-shadow 200ms;
}
.p-checkbox .p-checkbox-box {
  border-radius: 0;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-family: 'primeicons';
}

.p-radiobutton .p-radiobutton-box.p-highlight,
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--main);
  background: var(--main);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--outline);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--main);
  background: var(--main);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  border-color: var(--outline);
  outline-offset: 0px;
  outline: none;
  box-shadow: 0 0 0 2px var(--main-25);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus.p-highlight,
.p-checkbox .p-checkbox-box:not(.p-disabled).p-focus.p-highlight {
  border-color: var(--main);
}

.p-radiobutton.p-radiobutton-disabled {
  cursor: default;
}
.p-radiobutton .p-radiobutton-box.p-disabled,
.p-checkbox .p-checkbox-box.p-disabled {
  background-color: var(--bkg);
  opacity: 1;
  cursor: default;
}
.p-radiobutton .p-radiobutton-box.p-disabled.p-highlight,
.p-checkbox .p-checkbox-box.p-disabled.p-highlight {
  border-color: transparent;
  background-color: var(--main-60);
}
