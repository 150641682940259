@charset "UTF-8";

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/open-sans-v15-latin-300.eot");

  src:
    local("Open Sans Light"),
    local("OpenSans-Light"),
    url("/assets/fonts/open-sans-v15-latin-300.eot#iefix") format("embedded-opentype"),
    url("/assets/fonts/open-sans-v15-latin-300.woff2") format("woff2"),
    url("/assets/fonts/open-sans-v15-latin-300.woff") format("woff"),
    url("/assets/fonts/open-sans-v15-latin-300.ttf") format("truetype"),
    url("/assets/fonts/open-sans-v15-latin-300.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/open-sans-v15-latin-regular.eot");

  src:
    local("Open Sans Regular"),
    local("OpenSans-Regular"),
    url("/assets/fonts/open-sans-v15-latin-regular.eot#iefix") format("embedded-opentype"),
    url("/assets/fonts/open-sans-v15-latin-regular.woff2") format("woff2"),
    url("/assets/fonts/open-sans-v15-latin-regular.woff") format("woff"),
    url("/assets/fonts/open-sans-v15-latin-regular.ttf") format("truetype"),
    url("/assets/fonts/open-sans-v15-latin-regular.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/open-sans-v15-latin-600.eot");

  src:
    local("Open Sans SemiBold"),
    local("OpenSans-SemiBold"),
    url("/assets/fonts/open-sans-v15-latin-600.eot#iefix") format("embedded-opentype"),
    url("/assets/fonts/open-sans-v15-latin-600.woff2") format("woff2"),
    url("/assets/fonts/open-sans-v15-latin-600.woff") format("woff"),
    url("/assets/fonts/open-sans-v15-latin-600.ttf") format("truetype"),
    url("/assets/fonts/open-sans-v15-latin-600.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/open-sans-v15-latin-700.eot");

  src:
    local("Open Sans Bold"),
    local("OpenSans-Bold"),
    url("/assets/fonts/open-sans-v15-latin-700.eot#iefix") format("embedded-opentype"),
    url("/assets/fonts/open-sans-v15-latin-700.woff2") format("woff2"),
    url("/assets/fonts/open-sans-v15-latin-700.woff") format("woff"),
    url("/assets/fonts/open-sans-v15-latin-700.ttf") format("truetype"),
    url("/assets/fonts/open-sans-v15-latin-700.svg#OpenSans") format("svg");
}
