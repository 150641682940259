// Define CSS custom properties for colors
:root {
  --main: #0069af;
  --main-light: #1a8fe2;
  --main-dark: #094977;
  --main-60: rgba(0, 105, 175, 0.6);
  --main-25: rgba(14, 107, 173, 0.25);
  --main-10: rgba(14, 107, 173, 0.1);

  --success: #478172;
  --success-light: #3db393;
  --success-dark: #315a4f;
  --success-60: rgba(71, 129, 114, 0.6);
  --success-25: rgba(71, 129, 114, 0.25);
  --success-10: rgba(71, 129, 114, 0.1);

  --warning: #f2c55f;
  --warning-light: #ffdf96;
  --warning-dark: #9c7417;
  --warning-60: rgba(242, 197, 95, 0.6);
  --warning-25: rgba(242, 197, 95, 0.25);
  --warning-10: rgba(242, 197, 95, 0.1);

  --danger: #cf4138;
  --danger-light: #ff6e64;
  --danger-dark: #982119;
  --danger-60: rgba(207, 65, 56, 0.6);
  --danger-25: rgba(207, 65, 56, 0.25);
  --danger-10: rgba(207, 65, 56, 0.1);

  --dark: #2f2f30;
  --light: #6d6d70;

  --mono-60: rgba(47, 47, 48, 0.6);
  --mono-25: rgba(47, 47, 48, 0.25);
  --mono-10: rgba(47, 47, 48, 0.1);

  --outline: #d4d4d4;
  --bkg: #f6f6f6;
}

$color-map: (
  "main": var(--main),
  "main-light": var(--main-light),
  "main-dark": var(--main-dark),
  "main-60": var(--main-60),
  "main-25": var(--main-25),
  "main-10": var(--main-10),
  "success": var(--success),
  "success-light": var(--success-light),
  "success-dark": var(--success-dark),
  "success-60": var(--success-60),
  "success-25": var(--success-25),
  "success-10": var(--success-10),
  "warning": var(--warning),
  "warning-light": var(--warning-light),
  "warning-dark": var(--warning-dark),
  "warning-60": var(--warning-60),
  "warning-25": var(--warning-25),
  "warning-10": var(--warning-10),
  "danger": var(--danger),
  "danger-light": var(--danger-light),
  "danger-dark": var(--danger-dark),
  "danger-60": var(--danger-60),
  "danger-25": var(--danger-25),
  "danger-10": var(--danger-10),
  "dark": var(--dark),
  "light": var(--light),
  "mono-60": var(--mono-60),
  "mono-25": var(--mono-25),
  "mono-10": var(--mono-10),
  "outline": var(--outline),
  "bkg": var(--bkg),
);
