.theme-favicon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: whitesmoke;
  background-position: center;
}

html {
  font-size: 1rem;
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family), Helvetica, Arial, sans-serif;
  background: var(--surface-ground);
  color: var(--dark);
  padding: 0;
  margin: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: auto;
}

// ///// Text ///// //

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-size: 1.25rem;
}

p {
  margin: 0;
}

.title1 {
  font-size: 3rem;
  font-weight: 300;
}

.title2 {
  font-size: 2rem;
  font-weight: 300;
}

.title3 {
  font-size: 1.5rem;
  font-weight: 300;
}

.title4 {
  font-size: 1.125rem;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  .title1 {
    font-size: 2.5rem;
  }

  .title2 {
    font-size: 1.5rem;
  }

  .title3 {
    font-size: 1.25rem;
  }
}

.subtitle {
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
}

.textBody {
  font-size: 0.875rem;
}

.link {
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: none;
}

.link_small {
  font-size: 0.75rem;
  font-weight: bold;
  text-decoration: none;
}

.fontW_semibold {
  font-weight: 600 !important;
}
.fontW_bold {
  font-weight: bold !important;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.pageTitle {
  margin: 1.5rem 0;
}

@media screen and (max-width: 767px) {
  .pageTitle_steps {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.label {
  display: block;
  margin-bottom: 0.375rem;
  font-size: 0.75rem;
  font-weight: normal;
}

.label_check {
  display: inline-flex;
  align-items: center;
  min-height: 1.5rem;
  margin: 0 0 0 0.75rem;
  font-size: 0.875rem;
  font-weight: normal;
}

.inputDescription {
  display: block;
  margin-top: 0.375rem;
  font-size: 0.75rem;

  ul {
    margin: 0.5rem 0;
    padding-left: 1.5rem;
  }
  li {
    margin-bottom: 0.5rem;
  }
}

.color_light {
  color: var(--light) !important;
}

// ///// Containers ///// //

.generalCont {
  display: flex;
  min-height: 100vh;
}

.mainCont {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem; /* header height */
}
.mainCont.hasSidebar {
  margin-top: 0;
}

@media screen and (max-width: 1023px) {
  .mainCont {
    margin-top: 3rem; /* header height (mobile) */
  }
  .mainCont.hasSidebar {
    margin-top: 3rem; /* sidebar height (mobile) */
  }
}

.mainContPublic {
  width: 100%;
  min-height: 100vh;
  max-width: 26.25rem;
  padding: 2rem;

  &.mainContPublic_large {
    max-width: 50%;
  }
}

@media screen and (max-width: 1023px) {
  .mainContPublic {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .mainContPublic,
  .mainContPublic.mainContPublic_large {
    max-width: unset;
  }
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;

  @media (max-width: 1023px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

.container_fluid {
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;

  @media (max-width: 1023px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media (max-width: 767px) {
  .container_steps {
    padding-right: 0;
    padding-left: 0;
  }
}

body .p-button.p-button-icon-only {
  min-width: unset;
}

.p-panel-header {
  padding: 0 Impor !important;
}

.card {
  padding: 1rem 1.5rem 1.5rem 1.5rem;

  &.card_paddingSmall {
    padding: 0.75rem 1rem 1rem 1rem;
  }
  &.card_border {
    border: 1px solid var(--outline);

    &.card_border_danger {
      border-color: var(--danger-60);
    }
  }
  &.card_shadow {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  }
}

@media (max-width: 767px) {
  .card.card_border.card_border_noMobile {
    border-left: none;
    border-right: none;
  }
}

// ///// Form ///// //

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.p-formgrid .p-field label:not(.control-error) {
  font-weight: 600;
}

label:not(p.radiobutton-label):not(.theme-mode-item-unchecked):not(.label_check) {
  white-space: nowrap;
}

.inputCont {
  margin-bottom: 1.5rem;
}

.inputCont_check {
  margin: 1rem 0;
}
.inputCont_check_inline {
  display: inline-block;
  margin-right: 1.5rem;
}

.inputIconCont {
  position: relative;

  &.right {
    .p-inputtext,
    .p-dropdown,
    .p-treeselect {
      padding-right: 2.5rem;
    }
    .p-button,
    .inputIcon {
      right: 0;
    }
  }

  &.left {
    .p-inputtext,
    .p-dropdown,
    .p-treeselect {
      padding-left: 2.5rem;
    }
    .p-button,
    .inputIcon {
      left: 0;
    }
  }

  .p-button,
  .inputIcon {
    position: absolute;
    top: 0;

    &.square fa-icon {
      font-size: 0.875rem;
    }
  }
}

.optionsCont {
  display: flex;
  gap: 1rem;

  > * {
    flex: 1;
  }

  &.optionsCont_3col {
    flex-wrap: wrap;

    > * {
      max-width: calc(33.33333% - 0.666666rem);
    }
  }
}

@media (max-width: 767px) {
  .optionsCont {
    flex-direction: column;

    &.optionsCont_3col {
      > * {
        max-width: unset;
      }
    }
  }
}

.optionInfoText {
  display: flex;
  font-size: 0.75rem;
  gap: 0.5rem;
  margin-top: 1rem;
}

.inputMultipleInlineCont {
  display: flex;
  gap: 1rem;
  align-items: center;

  > .p-button {
    flex-shrink: 0;
  }

  .inputMultipleInlineCont__dividerText {
    position: relative;
    top: -0.125rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 767px) {
  .inputMultipleInlineCont__btn {
    align-items: center;
    justify-content: center;
    width: 2.625rem;
    height: 2.625rem;
    padding: 0;

    fa-icon {
      font-size: 1rem;
    }
  }
  .inputMultipleInlineCont__btnText {
    display: none;
  }
}

// ///// Page heading ///// //

.pageHeading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 1.5rem 0;

  &.pageHeading_center {
    align-items: center;
  }
  &.pageHeading_end {
    align-items: flex-end;
  }
}

@media (max-width: 1023px) {
  .pageHeading,
  .pageHeading.pageHeading_center,
  .pageHeading.pageHeading_end {
    &:not(.columnMobileOnly) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media (max-width: 767px) {
  .pageHeading,
  .pageHeading.pageHeading_center,
  .pageHeading.pageHeading_end {
    flex-direction: column;
    align-items: flex-start;
  }
}

// ///// Utilities ///// //

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: none;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--outline);
}

.greyBkg {
  background-color: var(--bkg);
}

.unstyledButton {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  font-family: var(--font-family), Helvetica, Arial, sans-serif;
  text-decoration: none;
  background: transparent;
  color: var(--dark);
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;

  &:not(:disabled) {
    cursor: pointer;
  }
  &:not(:disabled):hover {
    color: var(--light);
  }
  &:not(:disabled):focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--mono-25);
  }
}

@mixin unstyledButton {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  font-family: var(--font-family), Helvetica, Arial, sans-serif;
  text-decoration: none;
  background: transparent;
  color: var(--dark);
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;

  &:not(:disabled) {
    cursor: pointer;
  }
  &:not(:disabled):hover {
    color: var(--light);
  }
  &:not(:disabled):focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--mono-25);
  }
}

.whiteLink {
  color: white;
  font-weight: bold;
  transition: color 200ms;
}

.textWidthLimit {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
}

.borderBottom_0 {
  border-bottom: none !important;
}

.statusDot {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1000px;
  background-color: var(--light);

  &.main {
    background-color: var(--main);
  }
  &.success {
    background-color: var(--success);
  }
  &.warning {
    background-color: var(--warning);
  }
  &.danger {
    background-color: var(--danger);
  }
}

.messageCircle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 1000px;
  border: 3px solid var(--outline);
  font-size: 3rem;
  font-weight: bold;
  color: var(--dark);

  &.main {
    border-color: var(--main);
    color: var(--main);
  }
  &.success {
    border-color: var(--success);
    color: var(--success);
  }
  &.warning {
    border-color: var(--warning);
    color: var(--dark);
  }
  &.danger {
    border-color: var(--danger);
    color: var(--danger);
  }
}

.footActionsCont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  gap: 1rem;
  flex-wrap: wrap;

  &.spaced {
    justify-content: space-between;
  }
  &.end {
    justify-content: flex-end;
  }

  &.padding {
    padding: 1.5rem;
  }
}

@media (max-width: 767px) {
  .footActionsCont {
    width: 100%;
    flex-direction: column;
  }
}

.control-error {
  width: auto;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--danger);
  display: block;
}

// ////////// Primeng Overrides ////////// //

// ///// Link ///// //

.p-link {
  font-family: var(--font-family), Helvetica, Arial, sans-serif;

  &:focus {
    box-shadow: 0 0 0 2px var(--main-25);
  }
}

// ///// Accordion ///// //

.p-accordion p-accordiontab .p-accordion-tab {
  margin: 0;
  border: 1px solid var(--outline);
}
.p-accordion p-accordiontab:not(:first-of-type) .p-accordion-tab {
  margin-top: -1px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 1.5rem;
  border: none;
  border-radius: 0;
  font-weight: 600;
  color: var(--dark);
  background-color: white;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  border: none;
  color: var(--dark);
  background-color: white;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  border: none;
  color: var(--dark);
  background-color: white;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: 0 0 0 2px var(--main-25);
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin: 0;
  border: 1px solid var(--dark);
}
.p-accordion.accordion_compact .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  font-size: 0.875rem;

  .p-accordion-toggle-icon {
    border-color: transparent;
  }
}

.p-accordion .p-accordion-content {
  padding: 0 1.5rem 1.5rem 1.5rem;
  border: none;
  font-weight: normal;
  color: var(--dark);
  background-color: white;
}

@media (max-width: 1023px) {
  .p-accordion .p-accordion-header .p-accordion-header-link {
    gap: 1rem;
    padding: 1rem;
  }

  .p-accordion .p-accordion-content {
    padding: 0 1rem 1rem 1rem;
  }
}

// ///// Detail Sidebar ///// //

.detailSidebar__header__sourceImgCont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
  border: 1px solid var(--outline);
  border-radius: 50%;
}
.detailSidebar__header__sourceImg {
  width: 100%;
}

.detailSidebar__title {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.detailSidebar__body {
  padding: 1.5rem;
}

.detailSidebar__actionsCont,
.detailSidebar__tabsCont,
.detailSidebar__messagesCont {
  padding-bottom: 1.5rem;
}

@media (max-width: 1023px) {
  .detailSidebar__header__sourceImgCont {
    display: none;
  }
}

// ///// Dialog ///// //

.p-component-overlay.p-dialog-mask {
  align-items: flex-start !important;
  overflow-y: scroll;
}

.p-dialog {
  max-height: unset !important;
  margin: 2rem 1rem;
  border-radius: 0;
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}
.p-dialog-top .p-dialog,
.p-dialog-bottom .p-dialog,
.p-dialog-left .p-dialog,
.p-dialog-right .p-dialog,
.p-dialog-top-left .p-dialog,
.p-dialog-top-right .p-dialog,
.p-dialog-bottom-left .p-dialog,
.p-dialog-bottom-right .p-dialog {
  margin: 2rem 0;
}

.p-dialog .p-dialog-header {
  padding: 1rem 1.5rem;
  border-radius: 0;
  color: var(--dark);
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 300;
  font-size: 1.5rem;
}

.p-dialog .p-dialog-header-icons {
  position: absolute;
  top: 0;
  right: 0;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  font-size: 0.75rem;
  color: var(--light);

  .pi {
    font-size: 0.75rem;
  }
}

.p-dialog .p-dialog-content {
  padding: 0 1.5rem 1.5rem 1.5rem;
  color: var(--dark);
  overflow-y: visible;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-dialog .p-dialog-footer {
  border-radius: 0;
  color: var(--dark);
}

.dialog_small {
  width: 100%;
  max-width: 480px;
}
.dialog_medium {
  width: 100%;
  max-width: 640px;
}
.dialog_large {
  width: 100%;
  max-width: 992px;
}
@media (max-width: 1023px) {
  .dialog_large {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .dialog_small,
  .dialog_medium {
    max-width: 100%;
  }
}

.p-dialog .p-dialog-footer button.p-confirm-dialog-accept,
.p-dialog .p-dialog-footer button.p-confirm-dialog-reject {
  margin: 0;

  .p-button-label {
    font-weight: 600;
  }
}

.p-dialog .p-dialog-footer button.p-confirm-dialog-reject {
  margin-right: 1rem;
  border-color: var(--dark);
  background-color: transparent;
  color: var(--dark);
}

@media (max-width: 767px) {
  .p-dialog .p-dialog-footer button.p-confirm-dialog-accept,
  .p-dialog .p-dialog-footer button.p-confirm-dialog-reject {
    width: 100%;
    margin: 0 0 1rem 0;
    text-align: left;
  }
}

// ///// Message ///// //

.p-inline-message {
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0;
}

.p-inline-message .p-inline-message-icon {
  margin: 0;
}

.p-inline-message.p-inline-message-info {
  background: var(--main-10);
  border-width: 0;
  color: var(--main-dark);

  .p-inline-message-icon {
    color: var(--main-dark);
  }
}
.p-inline-message.p-inline-message-success {
  background: var(--success-10);
  border-width: 0;
  color: var(--success-dark);

  .p-inline-message-icon {
    color: var(--success-dark);
  }
}
.p-inline-message.p-inline-message-warn {
  background: var(--warning-10);
  border-width: 0;
  color: var(--dark);

  .p-inline-message-icon {
    color: var(--dark);
  }
}
.p-inline-message.p-inline-message-error {
  background: var(--danger-10);
  border-width: 0;
  color: var(--danger-dark);

  .p-inline-message-icon {
    color: var(--danger-dark);
  }
}

// ///// Overlay ///// //

.p-menu.p-menu-overlay {
  border-radius: 0;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}

.topUnset {
  top: unset !important;
}

.p-overlaypanel {
  margin: 0 !important;
  border-radius: 0;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}
.p-overlaypanel:after,
.p-overlaypanel:before {
  display: none;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}

.dropdownHeader,
.dropdownFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 20rem;
  gap: 1rem;
  padding: 1rem;
  color: var(--dark);
}

.dropdownMenu {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &.marginBottom {
    margin-bottom: 0.5rem;
  }
}

.dropdownButton,
a.dropdownButton {
  @include unstyledButton;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
  width: 100%;
  min-width: 12rem;
  padding: 0.875rem 1rem;
  font-size: 0.875rem;
  color: var(--dark);
  transition:
    color 200ms,
    background-color 200ms,
    box-shadow 200ms,
    opacity 200ms;

  &:not(:disabled):hover {
    color: var(--dark);
    background-color: var(--mono-10);
  }
  &:focus {
    box-shadow: 0 0 0 2px var(--mono-25);
  }

  &.main {
    color: var(--main);

    &:not(:disabled):hover {
      color: var(--main);
      background-color: var(--main-10);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--main-25);
    }
  }
  &.danger {
    color: var(--danger);

    &:not(:disabled):hover {
      color: var(--danger);
      background-color: var(--danger-10);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--danger-25);
    }
  }
}

.dropdownButton__icon {
  display: flex;
  justify-content: center;
  width: 1.125rem;
}

.dropdownButton__icon_dropdown {
  position: absolute;
  right: 1rem;
  font-size: 0.75rem;
}

.dropdownMenu .divider {
  margin: 0.5rem 0;
}

// ///// Statusbar ///// //

.statusBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  line-height: 1;
  background-color: var(--bkg);
}

.statusBar__startCont {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

// ///// Status cards ///// //

.statusCardsCont {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 0 2rem;
  background-color: var(--bkg);

  &.small .statusCard {
    padding: 1.75rem 1rem 2.125rem 0;
  }
  &.small .statusCard__number {
    font-size: 2rem;
  }
}

.statusCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 100px;
  margin: 0;
  text-align: left;
  padding: 1rem 1rem 1.5rem 0;
}

.statusCard__number {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 3rem;
  font-weight: 300;
  color: var(--dark);
}

.statusCard__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.125rem;
  border-radius: 50%;
  color: var(--dark);
  background-color: var(--outline);

  &.main {
    color: var(--main-dark);
    background-color: var(--main-25);
  }
  &.success {
    color: var(--success-dark);
    background-color: var(--success-25);
  }
  &.warning {
    color: var(--warning-dark);
    background-color: var(--warning-25);
  }
  &.danger {
    color: var(--danger-dark);
    background-color: var(--danger-25);
  }
  &.mono {
    color: var(--dark);
    background-color: var(--mono-25);
  }
}

.statusCard__label {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--dark);
}

@media (max-width: 1023px) {
  .statusCardsCont {
    flex-wrap: nowrap;
    gap: 2.5rem;
    overflow-x: auto;
  }

  .statusCard {
    padding: 1rem 1rem 1rem 0;
  }

  .statusCard__number {
    font-size: 2rem;
  }
}

// ///// Table ///// //

.p-datatable-table {
  margin: 0;
  border-collapse: collapse;

  .p-datatable-thead > tr > th {
    padding: 1rem 0.5rem;
    border-color: var(--outline);
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--dark);
    background: transparent;

    &:first-of-type {
      padding-left: 1rem;
    }
    &:last-of-type {
      padding-right: 1rem;
    }
  }

  .p-datatable-tbody > .p-selectable-row:hover > td.tableTitleCell {
    text-decoration: underline;
  }

  .p-datatable-tbody > tr > td {
    padding: 0.5rem;
    border-color: var(--outline);
    font-size: 0.875rem;
    color: var(--dark);

    &:first-of-type {
      padding-left: 1rem;
    }
    &:last-of-type {
      padding-right: 1rem;
    }

    &.tableTitleCell {
      font-weight: 600;
    }

    .tableImg {
      display: block;
      height: 2rem;
    }

    &.tableButtonCell {
      width: 3rem;
    }
  }
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background-color: var(--bkg);
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  color: var(--dark);
  background: var(--mono-10);
}

.p-datatable-table .p-datatable-tbody > tr.p-highlight,
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: var(--main-10);
  color: var(--dark);

  > td.tableTitleCell {
    text-decoration: underline;
  }
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: 2px solid var(--main-25);
  outline-offset: 0;
}

.tableButton,
a.tableButton,
a:visited.tableButton {
  @include unstyledButton;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: none;
  color: var(--dark);
  background-color: transparent;
  transition:
    color 200ms,
    box-shadow 200ms;

  &:hover {
    color: var(--light);
  }
  &:focus {
    box-shadow: 0 0 0 2px var(--mono-25);
  }

  &.main {
    color: var(--main);

    &:hover {
      color: var(--main-light);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--main-25);
    }
  }

  &.success {
    color: var(--success);

    &:hover {
      color: var(--success-light);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--success-25);
    }
  }

  &.danger {
    color: var(--danger);

    &:hover {
      color: var(--danger-light);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--danger-25);
    }
  }
}

.tableMobileLabel {
  display: none;
}

@media (max-width: 1023px) {
  .tableRegularCell {
    min-width: 6rem;
  }

  .responsiveTable > .p-datatable-wrapper {
    overflow: visible;
  }

  .responsiveTable .p-datatable-table {
    display: block;
    margin-top: 0.5rem;

    .p-datatable-thead {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: none;
      overflow: hidden;
      opacity: 0;
      z-index: -1;
    }

    .p-datatable-tbody {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      > tr {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 1rem 0 2.5rem;
        border-top: 1px solid var(--outline);

        &:nth-child(even) {
          background: white;
        }
      }

      > tr > td {
        display: block;
        padding: 0 0 1rem 0;
        border: none;

        &.tableImgCell {
          position: absolute;
          width: 2.5rem;
          padding: 0;
        }
        .tableImg {
          width: 100%;
          height: unset;
        }

        &.tableLeftMarginCellMobile {
          position: relative;
          left: 3.5rem;
          width: calc(100% - 3.5rem);

          &.tableSubtitleCell {
            margin-top: -0.75rem;
          }
        }

        &.tableRegularCell {
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;
          font-size: 0.75rem;
        }

        .tableMobileLabel {
          display: block;
          text-align: right;
          font-weight: 600;
          width: 30%;
        }

        &.tableBadgeCell {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 0;
        }

        &.tableButtonCell {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 2rem;
          padding: 0;

          &:nth-last-of-type(2) {
            right: 3rem;
          }
          &:nth-last-of-type(3) {
            right: 6rem;
          }
        }
      }
    }
  }
}

// ///// Table Pagination ///// //

.p-datatable .p-paginator-bottom {
  justify-content: flex-end;
  align-items: flex-start;
  padding: 1rem 0 0 0;
  border: none;
}

.p-paginator .p-paginator-current {
  flex-grow: 1;
  display: block;
  height: unset;
  margin: 0;
  padding: 0 0 1rem 0;
  font-size: 0.75rem;
  text-align: left;
  color: var(--dark);
  overflow: visible;
  cursor: default;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem;
  height: 2rem;
  border-radius: 0;
  border: 2px solid transparent;
  margin: 0 0.25rem;
  color: var(--dark);
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  border-color: var(--outline);
  background: transparent;
  color: var(--light);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  font-weight: 600;
  background: transparent;
  border-color: var(--main);
  color: var(--dark);
}

@media (max-width: 1023px) {
  .p-datatable .p-paginator-bottom {
    justify-content: flex-start;
    margin-top: 1.5rem;
    border-top: 1px solid var(--outline);
  }

  .p-paginator .p-paginator-current {
    width: 100%;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last,
  .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 1.75rem;
    height: 1.75rem;
    margin: 0 0.125rem;
  }
}

// ///// Tabs ///// //

.p-tabview .p-tabview-nav {
  padding: 2px;
  border: none;
}

.p-tabview .p-tabview-nav li {
  .p-tabview-nav-link {
    margin: 0 1rem;
    padding: 1rem 0;
    border-width: 0 0 2px 0;
    border-bottom-color: transparent;
    border-radius: 0;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    color: var(--dark);
  }

  &:first-of-type .p-tabview-nav-link {
    margin-left: 0;
  }
  &:last-of-type .p-tabview-nav-link {
    margin-right: 0;
  }

  &.p-highlight .p-tabview-nav-link {
    border-color: var(--main);
    color: var(--dark);
  }

  &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    border-color: var(--outline);
    color: var(--light);
  }
  &.p-highlight:not(.p-disabled):hover .p-tabview-nav-link {
    border-color: var(--main-light);
  }

  .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: 0 0 0 2px var(--main-25);
  }
}

.p-tabview .p-tabview-panels {
  padding: 1rem 0 0 0;
  border-radius: 0;
  color: var(--dark);
}

// ///// Toast ///// //

.p-toast {
  width: 23.5rem;
  max-width: calc(100% - 3rem);
  opacity: 1;
}

.p-toast.p-toast-bottom-right {
  bottom: 24px;
  right: 24px;
}

.p-toast .p-toast-message {
  border: none;
  border-radius: 0;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);

  &.p-toast-message-info {
    border: none;
    color: white;
    background-color: var(--main-dark);
  }
  &.p-toast-message-success {
    border: none;
    color: white;
    background-color: var(--success-dark);
  }
  &.p-toast-message-warn {
    border: none;
    color: var(--dark);
    background-color: var(--warning);
  }
  &.p-toast-message-error {
    border: none;
    color: white;
    background-color: var(--danger-dark);
  }
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border: none;
}

.toastContent {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.toastIcon {
  font-size: 1.5rem;
  flex-shrink: 0;
}

.toastTextCont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.125rem;
  width: 100%;
  font-size: 0.875rem;
}

.toastTitle {
  font-size: 1rem;
  font-weight: 600;
}

.p-toast .p-toast-message .p-toast-icon-close {
  width: 1rem;
  height: 1rem;
  border-radius: 0;
  font-size: 0.75rem;
  color: white;

  .pi {
    font-size: 0.75rem;
  }
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: white;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: var(--dark);
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
  background-color: transparent;
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.25);
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close:focus {
  box-shadow: 0 0 0 2px var(--mono-25);
}

// ///// Tooltip ///// //

.p-tooltip .p-tooltip-text {
  padding: 0.5rem 0.75rem;
  border-radius: 0;
  font-size: 0.875rem;
  background-color: var(--dark);
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: var(--dark);
}

// ///// Tree ///// //

.p-tree.companyTree {
  padding: 0;

  .p-tree-container .p-treenode {
    padding: 0;
  }

  .p-tree-container .p-treenode .p-treenode-content {
    padding: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    display: none;
  }

  .p-treenode-label {
    width: 100%;
  }

  .p-treenode-children {
    padding: 0 0 0 1rem;
    border-left: 4px solid var(--outline);
  }

  .p-treenode-droppoint {
    height: 1rem;
  }

  .companyNode {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.375rem 0.5rem;
    border: 1px solid var(--outline);
    border-left-width: 4px;
    border-radius: 0;

    &:focus {
      box-shadow: 0 0 0 2px var(--main-25);
    }

    .companyNode__startCont {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }

    .companyNode__dragIcon {
      padding: 0 0.25rem 0 0.5rem;
      font-size: 0.875rem;
      color: var(--light);
    }
    .companyNode__typeIcon {
      font-size: 0.75rem;
    }

    .companyNode__endCont {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .actionNode {
    border-left: 4px solid var(--outline);
  }
}

@media (max-width: 767px) {
  .p-tree.companyTree {
    .companyNode {
      flex-direction: column;
      gap: 1rem;
      padding: 0.75rem;
    }
  }
}

// ///// Option box icon ///// //

.optionIconSuccess {
  .optionBox__icon {
    color: var(--success);
  }
}
.optionIconReject {
  .optionBox__icon {
    color: var(--danger);
  }
}
