@mixin generate-sizing-classes($start, $end) {
  @for $i from $start through $end {
    .w-#{$i}rem {
      width: #{$i}rem !important;
    }
    .max-w-#{$i}rem {
      max-width: #{$i}rem !important;
    }
    .h-#{$i}rem {
      height: #{$i}rem !important;
    }
    .max-h-#{$i}rem {
      max-height: #{$i}rem !important;
    }
  }
}

@mixin generate-text-color-classes($color-map) {
  @each $name, $color in $color-map {
    .text-#{$name} {
      color: var(--#{$name}) !important;
    }
  }
}

@mixin generate-background-color-classes($color-map) {
  @each $name, $color in $color-map {
    .bg-#{$name} {
      background-color: var(--#{$name}) !important;
    }
  }
}

@mixin generate-border-color-classes($color-map) {
  @each $name, $color in $color-map {
    .border-#{$name} {
      border-color: var(--#{$name}) !important;
    }
  }
}
