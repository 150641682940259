@import "./fonts";
@import "../base-theme.scss";
@import "./theme-light.css";
@import "./mixins.scss";
@import "./colors";

/* Extend PrimeFlex classes */
@include generate-sizing-classes(31, 100);
@include generate-background-color-classes($color-map);
@include generate-text-color-classes($color-map);
@include generate-border-color-classes($color-map);

body {
  roofing-passport-home > section {
    background: black;
    color: white;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}
